<template>
  <div id="RedRock">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>
  
  <script>
import WhereWeAreMap from "@/components/global/WhereWeAreMap";

export default {
  name: "RedRock",
  components: {
    WhereWeAreMap,
  },
  data() {
    return {
      mapData: [
        {
          mapBg: require("@/assets/maps/bg-red-rock.jpg"),
          mapLine: "RedRockPath",
          venueBg: require("@/assets/maps/red-rock.png"),
          venue: "Red Rock",
          distance: "16.6",
          singleLineVenue: true,
          threeLineVenue: false,
        },
      ],
    };
  },
};
</script>
  
  <style lang="scss scoped">
</style>